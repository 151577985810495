<template>
  <CRow class="w-100">
    <vue-element-loading
      :active="paymentLoading"
      :text="paymentLoadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCol lg="12">
      <CRow v-if="currentUserType === 'Agency'">
        <CInput
          type="text"
          v-model="farmerPhone"
          placeholder="جستجو بر اساس تلفن"
          class="searchInput mb-0"
        />
        <CInput
          type="text"
          v-model="farmName"
          placeholder="جستجو بر اساس نام زمین"
          class="searchInput mb-0"
        />
        <CButton color="primary" @click="getFarmsList()">
          <i class="fa fa-search" aria-hidden="true"></i>
        </CButton>
      </CRow>
      <list-with-ads
        :columns="{
          lg: 2,
          xl: 3,
          xxl: 4,
        }"
        :loading="paymentLoading"
      >
        <farmItem
          v-for="(item, index) in farmsList"
          :key="index"
          class="main-card h-100"
          :itemModel="item"
          @farmDateTimeValues="getFarmDateTimeSelected"
        />
      </list-with-ads>
    </CCol>
    <VueModal
      v-model="modelShow"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      modelStyle="width:30vw;max-width:30vw !important"
    >
      <component
        v-if="subForm != null"
        v-bind:is="subForm"
        :allInvoiceItems="allDateTimesFarms"
      />
    </VueModal>
    <VueModal
      :based-on="factorState && !paymentLoading"
      @close="hideFactor"
      title="فاکتور خرید"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      modelStyle="width:30vw;max-width:30vw !important"
    >
      <div class="mb-4" v-if="paymentType === 'factor'">
        <div>
          <CCallout color="success" v-if="factorModel.factorNumber !== '0000'">
            <strong class="h5">
              شماره فاکتور :
              <u>{{ factorModel.factorNumber }}</u>
            </strong>
            <br />
            <small :class="factorModel.state ? 'text-success' : 'text-danger'">
              {{ factorModel.message }}
            </small>
          </CCallout>

          <CRow>
            <CCol :sm="6" class="mb-3">
              <CCard>
                <CCardBody>
                  <div class="text-medium-emphasis small">مبلغ نهایی</div>
                  <div class="fs-5 fw-semibold">
                    {{ formatPrice(factorModel.amount) }} ریال
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol :sm="6" class="mb-3">
              <CCard>
                <CCardBody>
                  <div class="text-medium-emphasis small">تعداد زمین</div>
                  <div class="fs-5 fw-semibold">
                    {{ factorModel.farmCount }}
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol :sm="6" class="mb-3">
              <CCard>
                <CCardBody>
                  <div class="text-medium-emphasis small">تعداد ماه</div>
                  <div class="fs-5 fw-semibold">
                    {{ factorModel.monthCount }}
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol :sm="6" class="mb-3">
              <CCard>
                <CCardBody>
                  <div class="text-medium-emphasis small">مجموع هکتار</div>
                  <div class="fs-5 fw-semibold">
                    {{ factorModel.sumFarmAreas }}
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>

          <CCallout color="info">
            <small>
              تخفیف: کد تخفیف {{ factorModel.discountPercentage }} ::
              {{ factorModel.discountDescription }}
            </small>
          </CCallout>

          <CCallout color="secondary">
            <small>
              شماره تراکنش {{ factorModel.transactionCode }} ::
              {{ factorModel.transactionDesc }}
            </small>
          </CCallout>

          <CButton color="success" class="w-100" @click="backToBuyService">
            <CIcon icon="cil-check-circle" /> خرید خدمات جدید
          </CButton>
        </div>
      </div>
      <div class="invoicePerfix" v-else-if="paymentType === 'invoice'">
        <farmDateTime :allInvoiceItems="allDateTimesFarms" />
        <div v-if="allDateTimesFarms.length > 0">
          <CRow>
            <CCol md="12">
              <div class="vertical-g-10 factor-details" v-if="nationalityState">
                <div class="y-center-g-5">
                  <h6>هزینه هر هکتار</h6>
                  <h6>{{ formatPrice(userTypeCostAmount) }} ریال</h6>
                </div>
                <div class="y-center-g-5">
                  <h6>مجموع هکتار ها</h6>
                  <h6>{{ faktorArea.toFixed(2) }} هکتار</h6>
                </div>
                <div class="y-center-g-5">
                  <h6>تعداد ماه ها</h6>
                  <h6>{{ faktorDateCount }} ماه</h6>
                </div>
                <hr class="computingCuter mr-5" />
                <div class="y-center-g-5">
                  <h6>مبلغ فاکتور</h6>
                  <h6>{{ formatPrice(paymentAmount) }} ریال</h6>
                </div>
                <div class="y-center-g-5">
                  <h6>10% ارزش افزوده</h6>
                  <h6>{{ formatPrice(addedTax) }} ریال</h6>
                </div>
                <div class="y-center-g-5">
                  <h6>هزینه قابل پرداخت</h6>
                  <h6>{{ formatPrice(paymentAmountWithDiscount) }} ریال</h6>
                </div>
                <div v-if="discountFestivalId == null" class="y-center-g-5">
                  <CInput
                    class="mb-0"
                    type="text"
                    v-model="userDiscountCode"
                    placeholder="کد تخفیف را وارد کنید"
                  />
                  <CButton
                    v-c-tooltip="{
                      content: 'اعمال کد تخفیف',
                      placement: 'top',
                    }"
                    class="default"
                    @click="checkAndGetOfferCodeWithCode()"
                  >
                    <font-awesome-icon icon="percentage" />
                  </CButton>
                </div>
              </div>
              <div class="nationalityStyle" v-else>
                <label for="nationalityCode">کد ملی خود را وارد کنید</label>
                <CInput
                  type="text"
                  id="nationalityCode"
                  placeholder="کد ملی صحیح را وارد کنید..."
                  v-model="nationalityCode"
                />
                <label for="address">آدرس محل سکونت خود را وارد کنید</label>
                <CInput
                  type="text"
                  id="address"
                  placeholder="آدرس پستی خود را وارد کنید..."
                  v-model="address"
                />
                <CButton
                  color="primary"
                  block
                  @click="saveUserNationalityCode()"
                >
                  ذخیره اطلاعات و پرداخت
                </CButton>
              </div>
            </CCol>
          </CRow>
          <hr class="mt-4" />
          <CRow class="mt-3" align-horizontal="center">
            <h4
              class="festivalInfoPayment"
              v-if="discountFestivalId != null || discountCodeId != null"
            >
              {{ discountText }}
            </h4>
            <CCol col="auto" v-if="nationalityState" class="mb-3">
              <CButton
                color="primary"
                class="y-center-g-5"
                @click="checkNationalityState('Bank')"
                v-if="currentUserType != 'Agency'"
              >
                <font-awesome-icon icon="receipt" />
                <span> پرداخت مستقیم </span>
              </CButton>
            </CCol>
            <CCol col="auto" v-if="nationalityState">
              <CButton
                color="primary"
                class="y-center-g-5"
                @click="checkNationalityState('Account')"
              >
                <font-awesome-icon icon="wallet" />
                <span> پرداخت از کیف پول </span>
              </CButton>
            </CCol>
          </CRow>
        </div>
        <no-data
          v-else
          message="ابتدا زمین خود را انتخاب کنید و تاریخ های مورد نظر را به سبد خرید خود اضافه کنید."
          show
        ></no-data>
      </div>
      <div v-else>
        <h4 class="dangerPageError">خطا در تولید فاکتور ؛ مجدد تلاش کنید.</h4>
      </div>
    </VueModal>
  </CRow>
</template>

<script>
import farmItem from "./farmFormBtn/farmItem.vue";
import farmDateTime from "./farmFormBtn/farmDateTime.vue";
import { mapActions } from "vuex";
import polyGonStructure from "../../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import { VueEllipseProgress } from "vue-ellipse-progress";
import { domainName } from "../../../constants/config";
import { getCurrentUser } from "../../../utils/index";
import ListWithAds from "../../../components/list-with-ads.vue";
import NoData from "../../../components/no-data.vue";

export default {
  components: {
    NoData,
    ListWithAds,
    farmItem,
    VueEllipseProgress,
    farmDateTime,
  },
  props: {
    factorState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      farmsList: [],
      details: [],
      paymentLoading: false,
      paymentLoadingText: "در حال دریافت اطلاعات ...",
      farmCountSelected: 0,
      sumAreas: 0,
      farmCount: 0,
      selectedProgress: 0,
      userTypeCostAmount: 0,
      userTypeAccountId: null,
      userTypeCostId: null,
      paymentType: "invoice",
      buyServiceId: 0,
      state: null,
      userDiscountCode: null,
      tryDiscountCodeChecked: 3,
      discountCodeId: null,
      discountFestivalId: null,
      discountPercentage: 0,
      discountText: "هیچ کد تخفیفی برای شما تایید نشده",
      subForm: null,
      modelShow: false,
      modalTitle: "",
      farmerPhone: "",
      farmName: "",
      faktorArea: 0,
      faktorDateCount: 0,
      allDateTimesFarms: [],
      paymentAmount: 0,
      paymentAmountWithDiscount: 0,
      addedTax: 0,
      factorModel: {
        buyServiceId: 0,
        state: null,
        message: "",
        factorNumber: "0000",
        farmCount: null,
        monthCount: null,
        sumFarmAreas: null,
        amount: null,
        discountPercentage: null,
        discountDescription: null,
        transactionCode: null,
        transactionDesc: null,
      },
      nationalityCode: null,
      address: null,
      nationalityState: true,
      nationalityBtnClick: "",
      currentUserType: "",
    };
  },
  methods: {
    ...mapActions("farm", ["Gets"]),
    ...mapActions("financialUser", [
      "UserCostAmount",
      "NationalityCodeState",
      "AddNationalityCode",
      "DiscountsGetOfferCodeWithCode",
      "DiscountsGetFestivalWithDate",
      "AccountRequestInvoice",
      "SuccessWithAccountPayment",
      "BankRequestInvoice",
      "SuccessWithBankPayment",
      "GetBuyServicePerfixWithId",
    ]),
    async hideFactor() {
      this.$emit("hideFactor");
    },
    async checkNationalityState(funcName) {
      this.paymentLoading = true;
      this.paymentLoadingText = "در حال بررسی اطلاعات کاربری ...";
      let resultNationalityState = await this.NationalityCodeState();
      this.nationalityState = resultNationalityState.data.state;
      this.nationalityCode = resultNationalityState.data.nationalCode;
      this.address = resultNationalityState.data.address;

      this.paymentLoading = false;
      if (resultNationalityState.data.state) {
        if (funcName === "Bank") {
          await this.PaymentWithBankPage();
        } else if (funcName === "Account") {
          await this.PaymentWithAccount();
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در بررسی کد ملی<br /><br />`,
            type: "error",
          });
        }
      } else {
        this.nationalityBtnClick = funcName;
        this.$notify({
          title: "پیام سیستم",
          text: `اطلاعات را تکمیل کنید<br /><br />`,
          type: "info",
        });
      }
    },
    async saveUserNationalityCode() {
      let checkResult = this.vmsNationalCode(this.nationalityCode);
      if (checkResult) {
        this.paymentLoading = true;
        this.paymentLoadingText = "در حال ذخیره اطلاعات ...";
        let resultNationalityInsert = await this.AddNationalityCode({
          nationalityCode: this.nationalityCode,
          address: this.address,
        });
        console.log("resultNationalityInsert", resultNationalityInsert);
        this.paymentLoading = false;
        if (resultNationalityInsert.data) {
          this.nationalityState = true;
          if (this.nationalityBtnClick === "Bank") {
            await this.PaymentWithBankPage();
          } else if (this.nationalityBtnClick === "Account") {
            await this.PaymentWithAccount();
          } else {
            this.$notify({
              title: "پیام سیستم",
              text: `خطا در بررسی اطلاعات<br /><br />`,
              type: "error",
            });
          }
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در ثبت اطلاعات<br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `اطلاعات وارد شده صحیح نیست<br /><br />`,
          type: "error",
        });
      }
    },
    async getFarmsList() {
      this.farmsList = [];
      this.sumAreas = 0;
      this.paymentLoading = true;
      this.paymentLoadingText = "در حال دریافت اطلاعات...";
      let result = await this.Gets({
        farmName: this.farmName,
        farmerPhone: this.farmerPhone,
      });
      if (result) {
        if (result.data.length > 0)
          this.selectedFarmItem = result.data[0].farmId;
        this.farmsList = result.data.map((item, id) => {
          let polygon = item.area.substring(
            item.area.indexOf("("),
            item.area.indexOf(")")
          );
          polygon = polygon.replaceAll("(", "");
          polygon = polygon.replaceAll(")", "");
          polygon = polygon.split(", ");
          polygon = polygon.map((item) => item.split(" "));
          polyGonStructure.features[0].geometry.coordinates = [polygon];
          let farmArea = turf.area(polyGonStructure.features[0].geometry);
          item.farmArea = Intl.NumberFormat("fa").format(
            (farmArea / 10000).toFixed(2)
          );
          this.sumAreas += farmArea / 10000;
          let farmAreaReal = (farmArea / 10000).toFixed(2);
          return { ...item, id, farmAreaReal };
        });
        this.farmCount = this.farmsList.length;
      }
      this.getSelectedProgress();
      this.paymentLoading = false;
    },
    async getUserCostTypeAmount() {
      let result = await this.UserCostAmount();
      if (result.succeeded) {
        if (result.data.ammount > 0) {
          this.userTypeCostAmount = result.data.ammount;
          this.userTypeAccountId = result.data.financialAccountId;
          this.userTypeCostId = result.data.typeCostId;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `هزینه خدمات کاربری شما خطا دارد <br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `هزینه خدمات کاربری شما خطا دارد <br /><br />`,
          type: "error",
        });
      }
    },
    async checkAndGetOfferCodeWithCode() {
      if (this.tryDiscountCodeChecked > 0) {
        this.paymentLoading = true;
        this.paymentLoadingText = "در حال بررسی کد تخفیف وارد شده";
        let result = await this.DiscountsGetOfferCodeWithCode({
          code: this.userDiscountCode,
        });
        this.paymentLoading = false;
        if (result.succeeded) {
          if (result.data.id > 0) {
            this.discountCodeId = result.data.id;
            this.discountPercentage = result.data.percentage;
            this.discountText = `اعمال ${result.data.percentage} درصد تخفیف برای کد تخفیف ${result.data.code}`;
            this.discountFestivalId = null;
            this.paymentAmount = Math.round(
              this.userTypeCostAmount * this.faktorArea * this.faktorDateCount
            );
            this.paymentAmountWithDiscount = Math.round(
              this.paymentAmount -
                (this.paymentAmount * this.discountPercentage) / 100
            );
          } else {
            this.tryDiscountCodeChecked = this.tryDiscountCodeChecked - 1;
            this.$notify({
              title: "پیام سیستم",
              text: `کد وارد شده معتبر نیست ؛ تنها ${this.tryDiscountCodeChecked} تعداد دیگر مجاز هستید. <br /><br />`,
              type: "error",
            });
          }
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در دریافت اطلاعات <br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `تعداد دفعات مجاز شما به اتمام رسیده است<br /><br />`,
          type: "error",
        });
      }
    },
    async checkAndGetFestivalOffer() {
      this.paymentLoading = true;
      this.paymentLoadingText = "در حال بررسی جشنواره ها";
      let result = await this.DiscountsGetFestivalWithDate({});
      this.paymentLoading = false;
      if (result.succeeded) {
        if (result.data) {
          if (result.data.id > 0) {
            this.discountFestivalId = result.data.id;
            this.discountPercentage = result.data.percentage;
            this.discountText = `اعمال ${result.data.percentage} درصد تخفیف برای جشنواره  ${result.data.name}`;
            this.discountCodeId = null;
          }
        } else {
          this.discountFestivalId = null;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا در دریافت اطلاعات <br /><br />`,
          type: "error",
        });
      }
    },
    async PaymentWithAccount() {
      this.paymentLoading = true;
      this.paymentLoadingText = "در حال ارسال اطلاعات ...";
      let model = {
        BuyServiceId: this.buyServiceId,
        FarmsAndDateTimes: this.allDateTimesFarms,
        OfferCodeId: this.discountCodeId,
        OfferFestivalId: this.discountFestivalId,
        CallBackUrl: null,
        BuyServiceType: 2,
        Amount: this.paymentAmountWithDiscount,
      };
      let result = await this.AccountRequestInvoice({ invoiceModel: model });
      this.paymentLoading = false;
      if (result.succeeded) {
        if (result.data) {
          if (
            Math.round(result.data.amount) == this.paymentAmountWithDiscount
          ) {
            this.paymentType = "factor";
            this.factorModel.buyServiceId = result.data.buyServiceId;
            await this.SuccessPaymentWithAccount(true);
          } else {
            await this.SuccessPaymentWithAccount(false);
          }
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در تولید پیش فاکتور<br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
    },
    async SuccessPaymentWithAccount(state) {
      this.paymentLoading = true;
      this.paymentLoadingText = "ثبت نهایی خرید ...";
      let model = {
        buyServiceId: this.factorModel.buyServiceId,
        state: state,
      };
      let result = await this.SuccessWithAccountPayment({
        successModel: model,
      });
      this.paymentLoading = false;
      if (result.succeeded) {
        this.paymentType = "factor";
        this.factorModel.buySertviveId = result.data.buySertviveId;
        this.factorModel.state = result.data.state;
        this.factorModel.message = result.data.message;
        this.factorModel.factorNumber = result.data.factorNumber;
        this.factorModel.farmCount = result.data.farmCount;
        this.factorModel.monthCount = result.data.monthCount;
        this.factorModel.sumFarmAreas = result.data.sumFarmAreas;
        this.factorModel.amount = result.data.amount;
        this.factorModel.discountPercentage = result.data.discountPercentage;
        this.factorModel.discountDescription = result.data.discountDescription;
        this.factorModel.transactionCode = result.data.transactionCode;
        this.factorModel.transactionDesc = result.data.transactionDesc;
        this.$emit("refreshForm");
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message}<br /><br />`,
          type: "error",
        });
      }
    },
    async PaymentWithBankPage() {
      this.paymentLoading = true;
      this.paymentLoadingText = "دریافت اطلاعات درگاه پرداخت";
      let model = {
        BuyServiceId: this.buyServiceId,
        FarmsAndDateTimes: this.allDateTimesFarms,
        OfferCodeId: this.discountCodeId,
        OfferFestivalId: this.discountFestivalId,
        CallBackUrl: `${domainName}#/buySentinelService`,
        BuyServiceType: 1,
      };
      let result = await this.BankRequestInvoice({ invoiceModel: model });
      this.paymentLoading = false;
      if (result.data.bankRefId) {
        if (Math.round(result.data.amount) == this.paymentAmountWithDiscount) {
          window.location.href = result.data.bankRefId;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در محاسبه پیش فاکتور <br /><br />`,
            type: "error",
          });
        }
      } else {
        console.log("result.message", result.message);
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در اتصال به درگاه؛با پشتیبانی تماس بگیرید",
          type: "error",
        });
        this.loading = false;
      }
    },
    async SuccessPaymentWithBankPage(id_get, buyServiceId) {
      this.$emit("showFactor");
      this.paymentLoading = true;
      this.paymentLoadingText = "ثبت نهایی خرید ...";
      let model = {
        buyServiceId: buyServiceId,
        transId: "",
        idGet: id_get,
      };
      let result = await this.SuccessWithBankPayment({
        successModel: model,
      });
      this.paymentLoading = false;
      if (result) {
        if (result.succeeded) {
          this.paymentType = "factor";
          this.factorModel.buySertviveId = result.data.buySertviveId;
          this.factorModel.state = result.data.state;
          this.factorModel.message = result.data.message;
          this.factorModel.factorNumber = result.data.factorNumber;
          this.factorModel.farmCount = result.data.farmCount;
          this.factorModel.monthCount = result.data.monthCount;
          this.factorModel.sumFarmAreas = result.data.sumFarmAreas;
          this.factorModel.amount = result.data.amount;
          this.factorModel.discountPercentage = result.data.discountPercentage;
          this.factorModel.discountDescription =
            result.data.discountDescription;
          this.factorModel.transactionCode = result.data.transactionCode;
          this.factorModel.transactionDesc = result.data.transactionDesc;
          this.$emit("refreshForm");
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message}<br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: ` خطا در تایید نهایی خرید , با پشتیبانی تماس بگیرید.<br /><br />`,
          type: "error",
        });
      }
    },
    getFarmDateTimeSelected(farmItem) {
      if (farmItem.farmId > 0) {
        let itemExist = this.allDateTimesFarms.find((obj) => {
          return obj.farmId == farmItem.farmId;
        });
        if (itemExist != null) {
          if (farmItem.dateTimes.length > 0) {
            itemExist.dateTimes = [];
            itemExist.dateTimes = farmItem.dateTimes;
          } else {
            const indexOfObject = this.allDateTimesFarms.findIndex((object) => {
              return object.farmId === farmItem.farmId;
            });
            this.allDateTimesFarms.splice(indexOfObject, 1);
          }
        } else {
          if (farmItem.dateTimes.length > 0) {
            this.allDateTimesFarms.push(farmItem);
          }
        }
        this.computingFaktor();
      }
    },
    async getPerfixPayBuyServiceInfo(buyServiceId) {
      this.paymentLoading = true;
      this.paymentLoadingText = "در حال بررسی اطلاعات پیش فاکتور";
      let result = await this.GetBuyServicePerfixWithId({
        buyServiceId: buyServiceId,
      });
      this.paymentLoading = false;
      if (result) {
        if (result.succeeded) {
          if (result.data.farms) {
            this.allDateTimesFarms = result.data.farms.map((item) => {
              let polygon = item.farmArea.substring(
                item.farmArea.indexOf("("),
                item.farmArea.indexOf(")")
              );
              polygon = polygon.replaceAll("(", "");
              polygon = polygon.replaceAll(")", "");
              polygon = polygon.split(", ");
              polygon = polygon.map((item) => item.split(" "));
              polyGonStructure.features[0].geometry.coordinates = [polygon];
              let farmArea = turf.area(polyGonStructure.features[0].geometry);
              item.farmArea = (farmArea / 10000).toFixed(2);
              return { ...item };
            });
            this.buyServiceId = result.data.id;
            this.factorModel.buyServiceId = result.data.id;
            this.factorModel.factorNumber = result.data.number;
            this.paymentType = "invoice";
            this.computingFaktor();
            this.$notify({
              title: "پیام سیستم",
              text: `فاکتور آماده پرداخت است <br /><br />`,
              type: "success",
            });
          }
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message} <br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطای سیستمی ؛ با پشتیبانی تماس بگیرید<br /><br />",
          type: "error",
        });
      }
    },
    computingFaktor() {
      this.farmCountSelected = this.allDateTimesFarms.length;
      let sumArea = 0;
      this.allDateTimesFarms.forEach((obj) => {
        sumArea += parseFloat(obj.farmArea);
      });
      this.faktorArea = sumArea;

      let sumDateCount = 0;
      this.allDateTimesFarms.forEach((obj) => {
        sumDateCount += obj.dateTimes.length;
      });
      this.faktorDateCount = sumDateCount;

      this.paymentAmount = Math.round(
        this.userTypeCostAmount * this.faktorArea * this.faktorDateCount
      );

      this.paymentAmountWithDiscount = Math.round(
        this.paymentAmount -
          (this.paymentAmount * this.discountPercentage) / 100
      );
      this.addedTax = Math.round(this.paymentAmountWithDiscount * 0.1);
      this.paymentAmountWithDiscount = Math.round(
        this.paymentAmountWithDiscount + this.addedTax
      );
      this.getSelectedProgress();
      this.$emit("updateNumberInCart", sumDateCount);
    },
    formatPrice(Number) {
      Number += "";
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      Number = Number.replace(",", "");
      let x = Number.split(".");
      let y = x[0];
      let z = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
      return y + z;
    },
    backToBuyService() {
      this.paymentType = "invoice";
      this.$emit("hideFactor");
    },
    getSelectedProgress() {
      this.selectedProgress = (this.farmCountSelected * 100) / this.farmCount;
    },
    getAllDateTimes() {
      if (this.allDateTimesFarms.length > 0) {
        this.subForm = "farmDateTime";
        this.modalTitle = "نمایش پیش فاکتور...";
        this.modelShow = true;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `هیچ تاریخی خریداری نشده است <br /><br />`,
          type: "info",
        });
      }
    },
    vmsNationalCode(input) {
      if (
        !/^\d{10}$/.test(input) ||
        input == "0000000000" ||
        input == "1111111111" ||
        input == "2222222222" ||
        input == "3333333333" ||
        input == "4444444444" ||
        input == "5555555555" ||
        input == "6666666666" ||
        input == "7777777777" ||
        input == "8888888888" ||
        input == "9999999999"
      )
        return false;
      var check = parseInt(input[9]);
      var sum = 0;
      var i;
      for (i = 0; i < 9; ++i) {
        sum += parseInt(input[i]) * (10 - i);
      }
      sum %= 11;
      return (sum < 2 && check == sum) || (sum >= 2 && check + sum == 11);
    },
  },
  watch: {
    farmerPhone() {
      if (this.farmerPhone === "") this.getFarmsList();
    },
    farmName() {
      if (this.farmName === "") this.getFarmsList();
    },
  },
  mounted() {
    this.getFarmsList();
    this.getUserCostTypeAmount();
    this.checkAndGetFestivalOffer();
    this.currentUserType = getCurrentUser().type;
    if (this.$route.query.requestId != null) {
      if (this.$route.query.Status == "OK") {
        this.SuccessPaymentWithBankPage(
          this.$route.query.Authority,
          this.$route.query.requestId
        );
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: ` پرداخت وجه انجام نشد با پشتیبانی تماس بگیرید  <br /><br />`,
          type: "error",
        });
      }
    }
  },
};
</script>

<style>
.list-wrapper {
  max-height: 84dvh;
  overflow-y: auto;
  padding: 10px;
}

.no-data h4 {
  font-size: 14px;
  margin-top: 20px;
}

.buy-service-page .ads-order {
  grid-column: 1 / span 1 !important;
}

.factor-details .y-center-g-5 {
  justify-content: center;
}

.factor-details h6 {
  text-align: center;
}
</style>
